<template>
  <v-container :tag="tag" class="py-0">
    <div class="section-wrap" :class="propClass">
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'SectionWrapper',
  props: {
    tag: {
      type: String,
      default: 'section',
    },
    propClass: {
      type: [String, Object],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wrap {
  padding: 100px 0;
  @media (max-width: 900px) {
    padding: 32px 0;
  }
}
</style>
