<template>
  <div class="markian-card card-background flex-grow-1">
    <slot>Card Contents</slot>
  </div>
</template>

<script>
export default {
  name: 'MarkianCard',

}
</script>

<style lang="scss" scoped>
.markian-card {
  border-radius: 12px;
  padding: 50px 47px;
}

.icon-wrapper {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background-color: var(--v-primary-base, red);

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
