<template>
  <section-wrap class="text-center">
    <div class="text-t2 font-weight-bold" data-aos="fade-down">
      <slot name="title">{{ title }}</slot>
    </div>

    <markian-card-group :data="cards"
                        :cols="cardCols"
                        :sm="cardColsSm"
                        :md="cardColsMd"
                        :ls="cardColsLg"
                        :xl="cardColsXl"></markian-card-group>
  </section-wrap>
</template>

<script>
import SectionWrap from './SectionWrap';

import MarkianCardGroup from '@/components/MarkianCardGroup';

export default {
  name: 'CardSection',
  components: {
    SectionWrap,
    MarkianCardGroup,
  },
  props: {
    title: {
      type: String,
      default: 'T1_Title',
    },
    image: {
      default: null,
    },
    imageAspectRatio: {
      type: [Number, String],
      default: 1180/500,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    cards: {
      type: Array,
      default: () => ([]),
    },

    cardCols: {
      type: [Number, String],
      default: 12,
    },

    cardColsSm: {
      type: [Number, String],
      default: null,
    },

    cardColsMd: {
      type: [Number, String],
      default: null,
    },

    cardColsLg: {
      type: [Number, String],
      default: null,
    },

    cardColsXl: {
      type: [Number, String],
      default: null,
    },
  },
}
</script>
