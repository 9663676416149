import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

import ServiceIntro from '@/layouts/ServiceIntro';
import Home from '@/views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Root',
    component: ServiceIntro,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'solution/recommendation',
        name: 'Solution/Recommendation',
        component: () => import(/* webpackChunkName: "solution" */ '../views/Solution/Recommendation'),
      },
      {
        path: 'solution/search',
        name: 'Solution/Search',
        component: () => import(/* webpackChunkName: "solution" */ '../views/Solution/Search'),
      },
      {
        path: 'solution/audience',
        name: 'Solution/Audience',
        component: () => import(/* webpackChunkName: "solution" */ '../views/Solution/Audience'),
      },
      {
        path: 'solution/insight',
        name: 'Solution/Insight',
        component: () => import(/* webpackChunkName: "solution" */ '../views/Solution/Insight'),
      },
      {
        path: 'performance',
        name: 'Performance',
        component: () => import(/* webpackChunkName: "performance" */ '../views/Performance'),
      },
      {
        path: 'technology',
        name: 'Technology',
        component: () => import(/* webpackChunkName: "technology" */ '../views/Technology'),
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing'),
      },
    ],
  },
  {
    path: '*',
    component: ServiceIntro,
    children: [
      {
        path: '',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error404'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    if (scrollTo !== 0) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(goTo(scrollTo, {
            offset: 56,
          }));
        }, 700);
      });
    } else {
      return {x:0, y: 0};
    }

  },
  routes,
});

export default router;
