import Vue from 'vue';

Vue.config.errorHandler = (err, vm, info) => {
  try {
    // vm.$toast.error(err.message);
  } finally {
    console.error(err);
  }
};

window.onerror = function(message, source, lineno, colno, error) {
  console.error(error);

  return true;
};