<template>
  <v-app-bar
      app
      :color="$vuetify.theme.dark ? 'black' : 'white'"
      :elevate-on-scroll="!isMobileMenu"
      :height="isMobileMenu ? 56 : 112"
      elevation="2"
  >
    <div class="top-wrapper">
      <v-container fluid class="py-lg-6 px-xl-0 py-0">
        <v-row no-gutters class="align-center">
          <v-toolbar-title>
            <div>
              <v-img :src="logo"
                     :aspect-ratio="136/44"
                     :width="isMobileMenu ? 136 : 197.36" contain
                     @click="$router.push({path:'/'})"
                     style="cursor: pointer;"/>
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div class="d-flex flex-grow-1 align-center justify-space-between hidden-lg-and-up"
               v-if="!isMobileMenu">
            <div v-for="(menu, idx) in menuList"
                 class="menu-item text-t6 font-weight-bold"
                 :key="idx"
                 :ref="`menu-item-${idx}`">
              <v-menu :open-on-hover="true"
                      offset-y
                      :disabled="!menu.children"
                      attach=".menu-item"
                      bottom
                      close-delay="1">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <router-link class="menu-item__label"
                                 :to="menu.to"
                                 v-if="menu.to && !menu.external">
                      {{ menu.label }}
                    </router-link>
                    <a class="menu-item__label"
                       :href="menu.to"
                       v-else-if="menu.to && menu.external"
                       target="_blank">
                      {{ menu.label }}
                    </a>
                    <div class="menu-item__label" v-else>
                      {{ menu.label }}
                    </div>
                  </div>
                </template>

                <div class="menu-item__sub-menu">
                  <div class="py-2 text-t6 menu-item__sub-menu__item"
                       v-for="(subMenu, idx) in menu.children"
                       :key="idx">
                    <router-link :to="subMenu.to">
                      {{ subMenu.label }}
                    </router-link>
                  </div>
                </div>
              </v-menu>
            </div>

            <!--<v-btn color="primary"-->
            <!--       @click="$i18n.locale === 'ko-KR' ? $i18n.locale = 'en-US' : $i18n.locale = 'ko-KR'">-->
            <!--  toggle langs-->
            <!--</v-btn>-->

          </div>

          <v-btn v-if="isMobileMenu" small icon @click="toggleDrawer">
            <v-icon color="act" :class="drawer ? 'fa-rotate-90' : 'fa-rotate-0'">
              {{ !drawer ? 'fas fa-bars' : 'fas fa-times' }}
            </v-icon>
          </v-btn>

        </v-row>
      </v-container>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    isMobileMenu() {
      return this.$vuetify.breakpoint.mobile;
    },
    bp() {
      return this.$vuetify.breakpoint.width;
    },
    logo() {
      // return this.$vuetify.theme.dark ? require('@/assets/images/logo-vertical-white.svg') : require('@/assets/images/logo-horizontal.svg');
      return require('@/assets/images/logo-horizontal.svg');
    },
  },
  data: (vm) => ({}),
  methods: {
    toggleDrawer() {
      const status = this.drawer;
      this.$emit('update:drawer', !status);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item__sub-menu {
  background-color: var(--v-light-base, #fff);
  //background-color: var(--v-white-base, #fff);
}

.top-wrapper {
  width: 100%;
  max-width: 1400px - (60px*2);
  margin: auto;
}

.menu-item {
  $main-menu-default-color: var(--v-act-base, #18214D);
  $sub-menu-default-color: var(--v-paragraph-base, #3e4462);
  $focused-color: var(--v-primary-base, #4c9892);

  &__label {
    color: $main-menu-default-color;
    text-decoration: none;
    text-transform: capitalize;
    transition: .4s;
    cursor: pointer;

    &:hover {
      color: $focused-color;
    }
  }

  .router-link-active {
    color: $focused-color;
  }

  &__sub-menu {
    padding: 20px;

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 17px;
      }
    }

    a {
      color: $sub-menu-default-color;
      text-decoration: none;

      &:hover, &.router-link-active {
        color: $focused-color;
      }
    }
  }
}

::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
</style>
