<template>
  <section-wrap class="text-left">
    <v-row justify="space-between">
      <v-col cols="12" md="6" :order="isReversed ? 1 : 2">
        <div>
          <v-img :src="image" :lazy-src="image" :aspect-ratio="imageAspectRatio"
                 :alt="imageAlt ? imageAlt : title"
                 width="100%"
                 :data-aos="fadeRight"
                 v-if="image">
            <template #placeholder>
              <!--<v-row-->
              <!--    class="fill-height ma-0"-->
              <!--    align="center"-->
              <!--    justify="center"-->
              <!--&gt;-->
              <!--  <v-progress-circular-->
              <!--      indeterminate-->
              <!--      color="primary"-->
              <!--  ></v-progress-circular>-->
              <!--</v-row>-->
              <image-place-holder/>
            </template>
          </v-img>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="5" :order="isReversed ? 2 : 1">
        <div class="text-area">
          <div class="pb-3 text-t4 font-weight-bold"
               data-aos="fade-up"
               v-if="subTitle">
            <slot name="subTitle">{{subTitle}}</slot>
          </div>
          <div class="text-t2" :data-aos="fadeLeft">
            <slot name="title">{{ title }}</slot>
          </div>
          <div class="mt-4 description text-t4 paragraph--text"
               :data-aos="fadeLeft"
               v-if="description">
            <slot name="description">{{ description }}</slot>
          </div>
        </div>
        <div class="text-right" v-if="action">
          <v-btn text color="primary" class="pa-0 text-t6" @click="action"
                 :data-aos="fadeRight" data-aos-delay="400">
            <div>
              <span class="font-weight-bold text-capitalize mr-3">{{actionLabel}}</span>
              <div class="more-icon-wrap">
                <v-icon color="act" size="1em">fas fa fa-arrow-right</v-icon>
              </div>
            </div>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </section-wrap>
</template>

<script>
import SectionWrap from './SectionWrap';
import ImagePlaceHolder from '@/components/ImagePlaceHolder';

export default {
  name: 'PageHeaderSub',
  components: {
    SectionWrap,
    ImagePlaceHolder,
  },
  props: {
    subTitle: {
      type: [String, Boolean],
      default: 'T4_Menu',
    },
    title: {
      type: String,
      default: 'T1_Title',
    },
    description: {
      type: [String, Boolean],
      default: 'T4_Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    image: {
      default: null,
    },
    imageAspectRatio: {
      type: [Number, String],
      default: 580/500,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
    actionLabel: {
      type: String,
      default: 'Read More'
    },
  },
  computed: {
    isReversed() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
    fadeLeft() {
      return this.$vuetify.breakpoint.smAndDown ? 'fade-up' : 'fade-left';
    },
    fadeRight() {
      return this.$vuetify.breakpoint.smAndDown ? 'fade-down' : 'fade-right';
    },
  },
}
</script>

<style lang="scss" scoped>
.text-area {
  padding-bottom: 30px;
}

.more-icon-wrap {
  display: inline-flex;

  width: 2em;
  height: 2em;
  border-radius: 100%;
  background-color: var(--v-card-background-base, red);

  justify-content: center;
  align-items: center;
}
</style>
