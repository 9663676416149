<template>
  <v-app id="inspire">
    <app-bar :drawer.sync="drawer" :menu-list="menus"></app-bar>

    <v-navigation-drawer class="mobile-menu" app clipped right
                         v-model="drawer"

                         v-if="$vuetify.breakpoint.mobile">
      <div :style="{height: `${appBarHeight}px`}"></div>
      <ul class="mobile-menu__list">
        <li class="mobile-menu__item font-weight-bold text-t5 text-md-t6"
            v-for="(menu, idx) in menus" :key="idx"
            @click="drawer = false">

          <template>
            <router-link class="mobile-menu__label"
                         :to="menu.to"
                         v-if="menu.to && !menu.external">
              {{ menu.label }}
            </router-link>
            <a class="mobile-menu__label"
               :href="menu.to"
               v-else-if="menu.to">
              {{ menu.label }}
            </a>
            <div class="mobile-menu__label" v-else>
              {{ menu.label }}
            </div>
          </template>

          <ul class="sub-menu" v-if="menu.children">
            <li class="sub-menu__item" v-for="(subMenu, idx) in menu.children" :key="idx">
              <router-link :to="subMenu.to" class="sub-menu__label font-weight-regular">
                {{ subMenu.label }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </v-navigation-drawer>

    <v-main>
      <router-view data-aos="fade"/>
    </v-main>

    <Footer/>
  </v-app>
</template>

<script>
import AppBar from './AppBar';
import Footer from './Footer';

export default {
  name: 'ServiceIntroLayout',
  components: {
    AppBar,
    Footer,
  },
  computed: {
    isMobileMenu() {
      return this.$vuetify.breakpoint.mobile;
    },
    appBarHeight() {
      return this.$vuetify.application.top;
    },
  },
  data: () => ({
    drawer: false,
    menus: [
      {
        label: 'solution',
        // to: '/solution',
        children: [
          {
            label: 'Recommendation',
            to: '/solution/recommendation',
          },
          {
            label: 'Search',
            to: '/solution/search',
          },
          {
            label: 'Audience',
            to: '/solution/audience',
          },
          {
            label: 'Insight',
            to: '/solution/insight',
          },
        ],
      },
      {
        label: 'technology',
        to: '/technology',
      },
      {
        label: 'performance',
        to: '/performance',
      },
      {
        label: 'pricing',
        to: '/pricing',
      },
      {
        label: 'about us',
        to: 'https://braincolla.com',
        external: true,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer--fixed {
  z-index: 3;
}

.mobile-menu {
  $default-color: var(--v-act-base, #18214d) !default;
  $focus-color: var(--v-primary-base, #4c9892) !default;
  $gap: 32px !default;

  &__list {
    list-style: none;
    padding: $gap;
  }

  &__item {
    color: $default-color;
    margin-bottom: $gap;
  }

  &__label {
    text-decoration: none;
    text-transform: capitalize;
    color: inherit;
    transition: .4s;
    display: block;
    cursor: pointer;

    &:hover, &.router-link-active {
      color: $focus-color;
    }
  }

  .sub-menu {
    $default-color: var(--v-paragraph-base, #3e4462);
    $gap: 24px;

    list-style: none;
    padding-top: $gap;
    padding-left: $gap/2;

    &__item {
      cursor: pointer;
      color: $default-color;

      &:not(:last-child) {
        margin-bottom: $gap;
      }
    }

    &__label {
      text-decoration: none;
      text-transform: capitalize;
      color: inherit;
      transition: .4s;
      display: block;

      &:hover, &.router-link-active {
        color: $focus-color;
      }
    }
  }
}
</style>


