import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import '@/./errorHandler';
import vuetify from './plugins/vuetify';
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  created() {
    AOS.init({
      offset: -25,
      disableMutationObserver: false,
      // delay: 200,
      // duration: 600,
    });
  },
}).$mount('#app');
