import '@fortawesome/fontawesome-pro/css/all.css';
import '@/styles/vuetify-override.scss';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4C9892',
        'accent-1': '#EEAB39',
        'accent-2': '#D21776',
        'accent-3': '#00789F',

        disable: '#c7c7cc',
        paragraph: '#3e4462',
        act: '#18214d',

        'card-background': '#EFF2F6',
      },
      dark: {
        primary: '#4C9892',
        'accent-1': '#EEAB39',
        'accent-2': '#D21776',
        'accent-3': '#00789F',

        disable: '#c7c7cc',
        paragraph: '#ebebeb',
        act: '#fff',

        'card-background': '#EFF2F6',

        'light': '#333333',
        'dark': '#ffffff',
        'off-white': '#333333',
      },
    },
  },
});
