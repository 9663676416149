<template>
  <div class="image-place-holder">
      <v-progress-circular
          indeterminate
          size="50"
          color="primary light-5"
      ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'ImagePlaceHolder',
}
</script>

<style lang="scss" scoped>
.image-place-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background-color: rgba(142, 142, 142, 0.03);
}
</style>
