<template>
  <v-footer color="black" dark>
    <v-container>
      <div class="py-2">
        <v-img class="ma-auto"
               :src="require('@/assets/images/logo-vertical-white.svg')"
               :width="$vuetify.breakpoint.xsOnly ? 100 : 180" contain></v-img>
      </div>
      <v-divider class="mt-6 pb-6"></v-divider>
      <div class="company-info pa-2 text-t6 text-center">
        <div>
          {{ $t(companyName) }} ({{ $t('ceo') }} : {{ $t(ceoName) }})
        </div>
        <div>
          {{ $t(address) }}
        </div>
        <div>{{ $t('businessRegistrationNumber') }}: {{ $t(businessRegistrationNumber) }}</div>
        <div>
          Contact: <a :href="`mailto:${$t(email)}`">{{ $t(email) }}</a>
        </div>
        <!--<div>-->
        <!--  {{ $t(tel) }} -->
        <!--</div>-->
      </div>
      <div class="mt-6 text-t6 text-center">
        <div>{{ $t(copyright) }}</div>
        <div>{{ $t(illustrationCopyright) }}</div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    companyName: 'companyName',
    ceoName: 'ceoName',
    address: 'companyAddress',
    tel: 'companyTel',
    email: 'companyEmail',
    businessRegistrationNumber: 'companyBizNo',
    copyright: 'copyright',
    illustrationCopyright: 'illustrationCopyright',
  }),
}
</script>

<style lang="scss" scoped>
.company-info {
  a {
    color: inherit;
  }
}
</style>
