<template>
  <v-row class="markian-card__group text-center mt-15">
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl" class="d-flex" v-for="(card, idx) in data" :key="idx">
      <slot v-bind="card">
        <markian-card data-aos="fade-up" :data-aos-delay="`${(idx+1)*200}`">
          <div class="d-flex justify-center" v-if="card.icon">
            <div class="icon-wrapper">
              <v-icon dark size="2em">{{ card.icon }}</v-icon>
            </div>
          </div>
          <div class="text-t5 font-weight-bold py-6" v-if="card.title">{{ $t(card.title) }}</div>
          <div class="text-t5" v-if="$t(card.description)">
            {{ $t(card.description) }}
          </div>
        </markian-card>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
import MarkianCard from '@/components/MarkianCard';

export default {
  name: 'CardSection',
  components: {
    MarkianCard,
  },
  props: {
    data: {
      type: Array,
      default: () => ([
        {
          icon: 'far fa fa-times',
          title: 'T5_bold_Title',
          description: 'T5_Regular_Card Contents',
        },
      ]),
    },

    cols: {
      type: [Number, String],
      default: 12,
    },

    sm: {
      type: [Number, String],
      default: null,
    },

    md: {
      type: [Number, String],
      default: null,
    },

    lg: {
      type: [Number, String],
      default: null,
    },

    xl: {
      type: [Number, String],
      default: null,
    },
  },
}
</script>
