<template>
  <div>
    <page-header-main :sub-title="$t(header.subTitle)"
                      :title="$t(header.title).replaceAll(' x ', ' <span class=\'far fa-times primary--text\'></span> ')"
                      :description="$t(header.description)"
                      :image="header.image"
                      :image-aspect-ratio="2360/1001"
                      :image-alt="$t(header.title)"
    />
    <v-container class="py-10 py-md-0">
      <v-row class="justify-center align-center">
        <div class="py-2 py-sm-0 px-3 px-sm-5 flex-sm-grow-1"
             v-for="(partner, idx) in partners" :key="idx"
             style="max-width:180px;">
          <v-img class="partners-image" :src="partner.image" :alt="partner.name"
                 width="100%" min-width="120"
                 data-aos="fade-up" :data-aos-delay="`${(idx)*150}`"
                 :aspect-ratio="$vuetify.breakpoint.mdAndUp ? 1 : 160/80" contain/>
        </div>
      </v-row>
    </v-container>

    <card-section :title="$t(coreTech.title)" :cards="coreTech.cards" :card-cols-md="4"/>

    <page-header-sub
        v-for="(solution, idx) in solutions" :key="idx"
        sub-title=""
        :title="$t(solution.title)"
        :description="$t(solution.description)"
        :image="solution.image" :image-aspect-ratio="580/501"
        :action="solution.action">
    </page-header-sub>
  </div>
</template>

<script>
import PageHeaderMain from '@/layouts/ServiceIntro/Sections/PageHeaderMain';
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';
import CardSection from '@/layouts/ServiceIntro/Sections/CardSection';

export default {
  name: 'MainView',

  components: {
    PageHeaderMain,
    PageHeaderSub,
    CardSection,
  },
  data: (vm) => ({
    header: {
      subTitle: false,
      title: 'markianTitle',
      description: 'markianDesc',
      image: require('@/assets/images/main-visual@2x.png'),
      // image: '/img/main-visual@2x.png',
      imageAspectRatio: 100,
    },
    partners: [
      {
        name: 'Joara',
        image: require('@/assets/images/partners/Joara Logo@2x.png'),
      },
      {
        name: 'Google Cloud Platform',
        image: require('@/assets/images/partners/Google Cloud Partner@2x.png'),
      },
      {
        name: 'NIPA',
        image: require('@/assets/images/partners/NIPA Logo@2x.png'),
      },
      {
        name: 'Kdata',
        image: require('@/assets/images/partners/Kdata Logo@2x.png'),
      },
      {
        name: 'AI Hub',
        image: require('@/assets/images/partners/AI Hub@2x.png'),
      },
    ],

    coreTech: {
      title: 'markianCoreTech',
      cards: [
        {
          icon: 'fas fa fa-database',
          title: 'markianCoreTechShortDesc1',
          description: 'markianCoreTechDesc1',
        },
        {
          icon: 'fas fa fa-brain',
          title: 'markianCoreTechShortDesc2',
          description: 'markianCoreTechDesc2',
        },
        {
          icon: 'fas fa fa-cubes',
          title: 'markianCoreTechShortDesc3',
          description: 'markianCoreTechDesc3',
        },
      ],
    },

    solutions: [
      {
        solutionName: 'Recommendation',
        title: 'recomTitle',
        description: 'recomDesc',
        image: require('@/assets/images/solution_recommendation@2x.png'),
        action: () => {
          vm.$router.push('solution/recommendation');
        },
      },
      {
        solutionName: 'Search',
        title: 'searchTitle',
        description: 'searchDesc',
        image: require('@/assets/images/solution_search@2x.png'),
        action: () => {
          vm.$router.push('solution/search');
        },
      },
      {
        solutionName: 'Audience',
        title: 'audienceTitle',
        description: 'audienceDesc',
        image: require('@/assets/images/solution_audience@2x.png'),
        action: () => {
          vm.$router.push('solution/audience');
        },
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.partners-image {
  filter: saturate(0);
  opacity: 0.7;
}
</style>
