<template>
  <section-wrap class="text-center">
    <div class="text-area px-lg-5">
      <div class="pb-3 text-t4 font-weight-bold"
           data-aos="fade-up"
           v-if="subTitle">
        <slot name="subTitle">{{ subTitle }}</slot>
      </div>
      <div class="text-t2 px-lg-5" data-aos="zoom-out">
        <slot name="title">
          <span v-html="title"></span>
        </slot>
      </div>
      <div class="mt-4 description text-t4 paragraph--text" data-aos="fade-up" v-if="description">
        <slot name="description">{{ description }}</slot>
      </div>
    </div>

    <div class="pt-15">
      <v-img :src="image" :lazy-src="image" :aspect-ratio="imageAspectRatio"
             data-aos="zoom-in"
             :alt="imageAlt"
             v-if="image">
        <template #placeholder>
          <image-place-holder/>
        </template>
      </v-img>
    </div>
  </section-wrap>
</template>

<script>
import SectionWrap from './SectionWrap';
import ImagePlaceHolder from '@/components/ImagePlaceHolder';

export default {
  name: 'PageHeaderMain',
  components: {
    SectionWrap,
    ImagePlaceHolder,
  },
  props: {
    subTitle: {
      type: [String, Boolean],
      default: 'T4_Menu',
    },
    title: {
      type: String,
      default: 'T1_Title',
    },
    description: {
      type: [String, Boolean],
      default: 'T4_Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    image: {
      default: null,
    },
    imageAspectRatio: {
      type: [Number, String],
      default: 1180 / 500,
    },
    imageAlt: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  padding-bottom: 30px;

  .description {
    @media (min-width: 1180px) {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
}

</style>
