<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    checkScreenTheme() {
      this.$vuetify.theme.dark = window.matchMedia && window.matchMedia(
          '(prefers-color-scheme: dark)').matches;
    },
  },

  created() {
    // Check system screen mode & matching theme
    // this.checkScreenTheme();
    // window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
    //   this.checkScreenTheme();
    // });

    // Check browser language & Matching i18n
    // this.$i18n.locale = navigator.language.split('-')[0].toLowerCase();
    // this.$i18n.locale = navigator.language;
  },
};
</script>
